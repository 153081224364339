.nav-pills .nav-item .active {
  color: #ffffff !important;
}

.nav-pills .nav-item .nav-link:hover {
  background: #ffd180;
  color: #000;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #fe7a15 !important;
}

/* .nav-pills .nav-item .nav-link {
  background-color: #f8f8f8;
} */

.add-btn {
  font-size: 25px;
  cursor: pointer;
}

.step-frame {
  min-height: 70px;
  margin: 0px;
}

.step-icon-edit {
  font-size: 20px;
  color: blue;
  cursor: pointer;
}

.step-icon-del {
  font-size: 20px;
  color: red;
  cursor: pointer;
}

.phone-num .form-check input {
  margin-top: 11px;
}

.require-check {
  max-width: 10px !important;
}

.require-check input {
  width: 20px;
  height: 20px;
}

.require-check input {
  -webkit-appearance: none;
  background-color: #fafafa;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
}

.require-check input:active,
.require-check input:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.require-check input:checked {
  background-color: #e9ecee;
  border: 1px solid #adb8c0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #99a1a7;
}

.require-check input:checked:after {
  content: '\2714';
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 1px;
  color: #99a1a7;
}

.true-check input:checked:after {
  content: '\2714';
  font-size: 14px;
  position: absolute;
  top: 3px;
  left: -17px;
  color: #99a1a7;
}

.selected-check input:checked:after {
  content: '\2714';
  font-size: 14px;
  position: absolute;
  top: 3px;
  left: -10px;
  color: #99a1a7;
}

.uploader-label {
  font-weight: normal !important;
  cursor: pointer;
  font-size: 14px;
}

.uploader-label i {
  font-size: 25px;
  font-weight: normal !important;
}

.minimum-score {
  width: 70px !important;
}

.scroll-option {
  max-height: 400px !important;
  overflow-y: auto !important;
}

.option-frame {
  min-height: 45px !important;
  border: 1px solid #dee2e6 !important;
  border-radius: 3px !important;
}

.col-center {
  display: table-cell !important;
  vertical-align: middle !important;
}

.border-table {
  border-collapse: collapse !important;
  border-radius: 0px 12px 12px 12px !important;
  border-style: hidden !important;
  box-shadow: 0 0 0 1px black;
  /* border: 1px solid black; */
}
.header-name {
  color: red;
}
.bg {
  padding: 30px 40px !important;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 20px 80px rgba(0, 0, 0, 0.07);
}

.bg-2 {
  padding: 12px 24px !important;
}

.line {
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(116, 103, 240, 1) 35%, rgba(0, 212, 255, 1) 100%);
  height: 4px;
  width: 32px;
  border-radius: 2px;
  margin-bottom: 20px;
}
.process {
  border: 1px solid #d6d9dc;
  margin: auto;
  padding: 11px 17px;
  border-radius: 12px;
  /* margin-top: -2rem; */
}
.item-hov:hover {
  background-color: #c8cace;
  transition: 0.4s;
}

/*custom steps ant design */
/* .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon {
  background: orange !important;
  border-color: orange !important;
}

.ant-steps-item .ant-steps-item-finish .ant-steps-item-container:hover {
  color: red;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: orange;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: orange !important;
}

.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
  color: white;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: orange;
  border-color: orange;
}

.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role='button']:hover
  .ant-steps-item-icon {
  border-color: orange;
}

.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process):not(.ant-steps-item-wait)
  > .ant-steps-item-container[role='button']:hover
  .ant-steps-item-icon
  .ant-steps-icon {
  color: white;
}

.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process):not(.ant-steps-item-finish)
  > .ant-steps-item-container[role='button']:hover
  .ant-steps-item-icon
  .ant-steps-icon {
  color: orange;
}

.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role='button']:hover
  .ant-steps-item-content
  .ant-steps-item-title {
  color: orange;
}

.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process):not(.ant-steps-item-wait)
  > .ant-steps-item-container[role='button']
  .ant-steps-item-content
  .ant-steps-item-title::after {
  background-color: orange !important;
} */
