:root {
  --ant-primary-color: #1890ff;
  --section-radius: 8px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.content-wrapper {
  background: #eff3f9;
  min-height: calc(100vh - 50px);
}

.content-header {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.back-to-top {
}

.back-to-top-icon {
  font-size: 36px;
  background: rgba(66, 63, 63, 0.404);
  padding: 9px 15px;
  border-radius: 15%;
}

.cursor {
  cursor: pointer !important;
}

.not-allowed {
  cursor: not-allowed !important;
}

/* css item required form left to right in ant form label */
body .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  content: '';
  margin-right: 0;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.hidden {
  display: none;
}

/* css item required form left to right in ant form label */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
