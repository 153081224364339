.number-pad {
  display: flex;
  justify-content: center;
}

.number-pad__body {
  width: 55%;
}

.d-flex .number-btn {
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background-color: #e7e7e7;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  transition: 0s 0.3s;
}

.d-flex .number-btn:hover {
  /* background-color: #bccabc; */
}

.d-flex .number-btn:active {
  /* border: 2px solid #8d978d; */
  background-color: #8d978d;
  color: #ffffff;
  transition: 0s;
}

.d-flex .number-btn--green {
  color: #ffffff;
  background-color: #28a745;
}

.d-flex.number-btn--green:active {
  background-color: #1f8136;
  color: #ffffff;
  transition: 0s;
}

.d-flex .number-btn--green:hover {
  /* background-color: #1f8136; */
}

.d-flex .delete-box {
  min-width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex .delete-box .delete-btn {
  min-width: 25px;
  height: 25px;
  border: none;
  background-color: #e7e7e7;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
  position: relative;
  border-bottom-left-radius: 2.6px;
  border-top-left-radius: 2.6px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.d-flex .delete-box .delete-btn:hover {
  background-color: #bccabc;
}

.d-flex .delete-box .delete-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: -11px;
  width: 0;
  height: 0;
  border-top: 12.6px solid transparent;
  border-bottom: 12.6px solid transparent;
  border-right: 12.6px solid #e7e7e7;
}

.d-flex .delete-box .delete-btn:hover::before {
  border-right: 12.5px solid #bccabc;
}

.input-number-pad .ant-input {
  text-align: center;
  font-size: 20px;
}

/* @media screen and (min-width: 1400px) {
    .col-pad {
        width: 122px;
    }
} */
