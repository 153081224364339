* {
  margin: 0px;
  padding: 0px;
  font-size: 14px;

}
.iconplus {
  font-size: 20px !important;
}
html,
body {
  width: 100%;
  height: 100% !important;
}

#root {
  width: 100%;
  height: 100%;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.login-screen {
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  height: 100%;
  width: 100%;
}

.login-form {
  padding: 0px 30px 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}

form {
  width: 75%;
  text-align: center;
}

.loginForm img {
  width: 60%;
}

.form-group {
  width: 100%;
}

.form-group input {
  width: 100%;
  margin: auto;
  /* border: none; */
  /* border-bottom: 1px #333 solid; */
  /* border-radius: 0px; */
}

.form-group input:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.form-group {
  text-align: right;
}

.form-group a {
  color: #000;
}

.loginForm button {
  background: red;
  width: 60%;
}

.login-button-content {
  display: flex;
  justify-content: center;
  align-items: baseline;
  width: 50%;
  border-radius: 40px !important;
}

.LoginOutlined {
  margin-right: 3px;
}

#logo-televimer {
  width: 40%;
  position: relative;
  top: 40px;
}

#login-background {
  width: 100%;
}

.left-size {
  padding: 0px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#login-background {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
}

.container-fluid {
  padding: 0px !important;
}

#dang-nhap-text {
  font-size: 30px;
  color: red;
  font-family: 'segoe ui';
}

.login-screen {
  background-image: url('../assets/background_login.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
}

.form-login {
  background-color: white;
  height: 75%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 5%;
  float: right;
}

.form-forget-password {
  background-color: white;
  height: 100%;
  /* display: flex; */
  /* flex-wrap: wrap; */
  border-radius: 5%;
  float: right;
  padding: 50px;
  /* border-left: 4rem red solid; */
}

#forget-pass {
  color: blue;
}

#forget-pass:hover {
  color: red;
  text-decoration: none;
}

.ant-input:hover {
  border-color: #d9d9d9 !important;
}
#username:focus {
  border-color: black !important;
}
#password:focus {
  border-color: black !important;
}

@media only screen and (max-width: 600px) {
  #login-background {
    padding-bottom: 30px;
    height: 150%;
  }
  #logo-televimer {
    top: 20px;
  }
}

@media only screen and (min-width: 1130px) {
  #text-form-login {
    display: flex;
    max-width: 27%;
    margin-left: 82px;
  }
}

@media only screen and (max-width: 575px) {
  #text-form-login {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1100px) {
  .form-forget-password {
    background-color: white;
    height: 100%;
    border-radius: 5%;
    float: right;
    padding: 50px;
    width: 350px;
  }
}
