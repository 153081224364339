.group-info {
  margin-left: 30px !important;
  margin-right: 30px !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
  min-height: 150px;
  display: flex;
  align-items: center;
}

.group-info [class|='col'] img {
  margin: 10px 20px;
}

.company-name {
  color: rgba(211, 0, 0, 0.705);
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 5px;
}

/* [class|="col"]{
    padding-left: 0px !important;
    padding-right: 0px !important;
} */

.icon-contact {
  margin-right: 5px;
}

.function-area {
  text-align: center;
}

.function-btn {
  font-size: 14px !important;
  width: 150px !important;
  margin-left: 0px !important;
  margin-bottom: 5px;
  border-radius: 7px !important;
  text-align: center;
  opacity: 0.8;
}

/* css cho add company */
.add-company-form .row .form-control {
  margin-bottom: 15px;
}

.upload-icon {
  color: rgba(0, 0, 0, 0.305);
  font-size: 100px;
  display: block;
  margin-top: 20px;
  margin-bottom: -15px;
}
.upload-form-wrapper {
  width: 100%;
  height: 150px;
  /* border: 1px solid rgba(0, 0, 0, 0.305); */
  border-radius: 7px;
  text-align: center;
  /* min-height: 250px; */
  /* padding: 15px 10px 15px 10px; */
  background-color: #e0e0e0;
}

.label-upload p {
  font-weight: 400;
}

.label-upload span {
  font-weight: 100;
  font-size: 14px;
  font-style: italic;
}

.uploaded-pic {
  width: 100%;
}

#provice:focus {
  border-color: black !important;
  box-shadow: 0 0 0 0 black;
}

.ant-input-affix-wrapper:hover {
  border-color: #ced4da !important;
}

.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  border-color: black !important;
  box-shadow: 0 0 0 0 black !important;
}

/* td{
  cursor: default!important;
} */

@media only screen and (max-width: 700px) {
  .group-info {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .group-info [class|='col'] {
    text-align: center;
  }

  .function-btn {
    margin: 5px 2.5px 0px 2.5px;
    width: 130px;
  }

  .upload-form-wrapper {
    margin-bottom: 10px;
  }
}

.avt-pic {
  height: 150px;
}

.tele-item:hover {
  background-color: #c8cace !important;
  transition: 0.4s;
  cursor: pointer;
}

@media screen and (max-width: 760px) {
  .company-name {
    width: 100%;
  }
  .avatar {
    padding-right: 20px;
  }
  .group-info {
    text-align: center !important;
  }
}
