.header-btn {
  width: 100px;
  color: white !important;
  border-radius: 0 !important;
  margin-left: 10px;
}

.header-btn:first-child {
  margin-left: 0px !important;
}

.form-control {
  width: 100% !important;
}

.form-group [type="radio"] {
  width: auto !important;
}

.add-customer-form input {
  margin-bottom: 10px !important;
}

.inline-radio [type="radio"] {
  margin-top: 10px !important;
}

@media only screen and (max-width: 600px) {
  .form-control {
    margin-bottom: 5px !important;
  }
  .form-control:last-child {
    margin-bottom: 0px;
  }
}

.table-wrap {
  overflow-x: auto;
}

td,
th,
tr {
  text-align: center;
}

td {
  cursor: pointer;
}

.react-datepicker-wrapper {
  display: block;
  width: 100% !important;
}

.multi-select {
  --rmsc-h: 31px !important; /* Height */
}

.ant-upload-drag-icon > span > svg {
  font-size: 100px !important;
}

/* css cho CustomerDetail */
.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.col {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.info-row {
  margin-bottom: 15px !important;
}

.border-box {
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  padding: 20px 20px;
}

.inserted-point{
  width: 48px;
  height: 16px;
  background-color: #ccff90;
  margin-top: 13px;
}

.updated-point{
  width: 48px;
  height: 16px;
  background-color:#4fc3f7;
  margin-top: 13px;
}

.ignored-point{
  width: 48px;
  height: 16px;
  background-color: #ffccbc;
  margin-top: 13px;
}

.sub-status{
  display: flex;
  justify-content: space-around;
  margin-bottom: 6px;
}

.ant-table-footer{
  padding-right: 0px!important;
  background: white!important;
}
