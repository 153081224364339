.table-btn{
    font-size: 20px;
}

.table-btn-edit{ 
    color:#3662fa;
}
td a{
    color:#000;   
}

.job-general .row [class|="col"] label:not(.name-label){
    font-size: 20px;
}

@media only screen and (max-width: 700px) {
    .table-tabs .tabs{
        padding-left: 3px !important;
        padding-right: 0 !important;
    }
  }