.center-row {
    justify-content: center
}

.tele-icon {
    font-size: 25px;
    color: rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width:1024px) {
    .tele-icon {
        font-size: 20px;
    }
}

.smaller-upload {
    min-height: 200px !important;
}

.tele-input-col {
    text-align: left;
}

.tele-radio [type=radio] {
    margin-top: 4px !important;
}

.audio-player {
    /* border: 1px solid rgba(0, 0, 0, 0.7);
    border-radius: 5px; */
    height: 35px;
    width: 80%;
}

.big-title {
    font-size: 16px;
}

.img-wrap {
    overflow: auto;
    height: 170px;
    border: 1px solid black;
}

/* .cmnd-pic{
} */
@media only screen and (max-width:700px) {
    .audio-player {
        width: 100%;
    }
}