body {
  margin: 0;
  font-family: 'Ninuto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-size: 16px;
}

code {
  font-family: 'Ninuto', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.sidebar::-webkit-scrollbar {
  display: none !important;
}

.main-sidebar {
  z-index: 999;
}


::-webkit-scrollbar-track
{
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
}

::-webkit-scrollbar
{
	width: 6px;
	background-color: white;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
	background-color: #888;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
}

.dashboard_report_size {
  font-size: 12.5px !important;
}
