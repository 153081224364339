h2 {
  color: #fe7a15;
}

.nav-link {
  cursor: pointer;
}

button.close {
  padding-right: 20px !important;
  padding-left: 20px !important;
  display: flex;
  align-items: center;
}

button.close:hover {
  background: red;
  color: white !important;
}

.main-sidebar,
.sidebar {
  /* position: fixed !important; */
  /* padding-right: 5px; */
}

.sidebar {
  max-width: 250px;
}

.me-txt-logo {
  /* color: red; */
  font-size: 17px;
}

.me-txt-menu {
  font-size: 13.6px;
  font-family: 'Nunito';
}

.nav-icon {
  margin-right: 1rem !important;
}

.me-delete {
  display: none;
  margin-left: 50px;
  color: #ced4da;
  font-size: 15px;
  justify-content: initial;
}

@media (max-width: 990px) {
  .me-delete {
    display: inline;
  }
}

.me-menu-drop {
  background-color: #70707094;
  border-radius: 5px;
}

.me-sub-menu-item {
  margin-left: 15px;
}

.sidebar-mini .me-sub-menu-item {
  margin-left: 0px !important;
}

.actived {
  background-color: rgba(158, 157, 157, 0.3);
  border-radius: 3px;
  color: #fff !important;
}

.me-txt-menu .expand-icon {
  padding-top: 2px;
}

.sidebar .mt-2 .nav-sidebar .nav-item.sub-menu .active {
  background-color: rgba(158, 157, 157, 0.3) !important;
  color: #fe7a15 !important;
  border-left: 3px solid #fe7a15;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  font-size: 13.6px !important;
}

.sidebar .mt-2 .nav-sidebar .nav-item .active {
  background-color: #fe7a15 !important;
  color: #ffffff !important;
  border-left: 3px solid #fe7a15;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  font-size: 13.6px !important;
}

[class*='sidebar-dark-'] {
  background-color: #25253a !important;
}

html {
  background-color: #25253a;
}
.elevation-4 {
  box-shadow: none !important;
}

@media (min-width: 992px) {
  .hidden-md {
    display: none !important;
  }
}
