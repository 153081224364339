.legend {
    width: 100%;
    border-bottom: 1px solid #f1f1f1;
    line-height: .1em;
    margin: 10px 20px;
}
.legend span {
  background: #fff;
  padding: 0px 25px;
  margin-left: 30px;
}