.loader-container {
  background-color: rgba(0, 0, 0, 0.23);
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 1;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.loader {
  width: 200px !important;
  height: 200px !important;
}

/* set size of loading dots*/
.ant-spin-lg .ant-spin-dot i {
  width: 25px !important;
  height: 25px !important;
}

.ant-spin-lg .ant-spin-dot {
  width: 2em !important;
  height: 2em !important;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}