.btn-oversize{
    width:auto !important;
}

.modal-row{
    display:flex;
    align-items:center;
}

.modal-row [class|="col"] .modal-header{
    border-bottom:none !important;
}

.modal-row [class|="col"]{
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.form-control:focus {
    border-color: black !important;
    box-shadow: 0 0 0 0 black!important;
  }