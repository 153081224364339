.setting-div {
  min-height: 230px;
  /* border: 1px solid rgba(0, 0, 0, 0.9); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border-radius: 7px; */
  -webkit-box-shadow: 0px 2px 17px -5px rgba(0, 0, 0, 0.74);
  -moz-box-shadow: 0px 2px 17px -5px rgba(0, 0, 0, 0.74);
  box-shadow: 0px 2px 17px -5px rgba(0, 0, 0, 0.74);
}

.setting-div .row .setting-form {
  border: none !important;
  background: none !important;
  border-bottom: 1px solid black !important;
  border-radius: 0 !important;
  outline: none;
  box-shadow: none !important;
}

.setting-div .row .setting-label {
  font-size: 30px;
  font-weight: bold;
}

.header-btn {
  width: 100px;
  color: white !important;
  border-radius: 0 !important;
  margin-left: 10px;
}

.btnDelete {
  font-size: 20px;
  color: #ff0000;
  cursor: pointer;
  margin-right: 10px;
}

.btnEdit {
  font-size: 20px;
  color: #1896a3;
  cursor: pointer;
  margin-right: 10px;
}

@media only screen and (max-width: 700px) {
  .setting-div {
    margin-left: 10px;
    margin-right: 10px;
  }
}
