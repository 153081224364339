.button-wrapper{
    display: flex;
    justify-content: flex-end
}
.btn{
    margin-left: 10px;
}
.btnDelete {
    font-size: 20px;
    color: #ff0000;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .btnEdit {
    font-size: 20px;
    color: #1896a3;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .btnInfo {
    font-size: 20px;
    color: #1896a3;
    cursor: pointer;
    margin-right: 10px;
  }