.group-info-request {
  min-height: 100px !important;
  cursor: pointer;
}

.group-info-request:hover {
  background: #e9ecef;
}
.group-info-request .header .row {
  text-align: center;
  font-weight: bold;
  color: red;
}

.group-info-request .content .upper span {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 18px;
}

.group-info-request .content .upper span,
.group-info-request .content .lower {
  color: #212529 !important;
}

a:hover {
  text-decoration: none !important;
}

.group-info-request .content .lower [class|='col'] {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.group-info-request .footer .upper span {
  display: inline-block;
  margin-bottom: 10px;
}

.accept {
  color: rgb(19, 19, 230) !important;
}

.reject {
  color: red;
}

.waiting {
  color: orange;
}

.unknown {
  color: rgba(0, 0, 0, 0.4);
}

.req-hov:hover {
  background-color: #c8cace !important;
  transition: 0.4s;
  cursor: pointer;
}

.ant-image-img {
  object-fit: contain;
  border-radius: 8px;
}

#test-table-xls-button {
  border: none !important;
  border-radius: 5px;
  padding: 6px 16px;
  margin-left: 5px;
  color: white !important;
}

.download-table-xls-button {
  color: white;
}

.nav-tabs .nav-item.show,
.nav-tabs .nav-link.active {
  color: #fff !important;
  background-color: #fe7a15 !important;
  border-color: #dee2e6 #dee2e6 #fff;
}
.tab-item {
  color: #43425d !important;
  background-color: #f8f8f8 !important;
  margin-right: 5px !important;
}

/* css cho register request detail */
.title-icon {
  font-size: 50px;
  display: inline-block;
  color: rgba(0, 0, 0, 0.5);
  padding-left: 35px;
}

.request-body .content-header .title {
  align-items: center;
}

.request-body .content-header .title .name {
  padding-left: 10px !important;
}

.request-body .personal-info,
.assessment {
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
  border-radius: 7px;
  margin: 7px 10px;
  overflow-x: auto;
}

.assessment .content [class|='col'] .row {
  align-items: center;
  margin-top: 5px;
}

.passed {
  background-color: #77dd7781 !important;
  border: none !important;
}

.failed {
  background-color: #ff69615e !important;
  border: none !important;
}

.status-icon {
  font-size: 40px;
  color: white;
}

.link-infor:hover {
  color: blue !important;
}

.id-number-pic {
  height: 150px;
}

.icon-oppotunity {
  font-size: 1.7rem;
  color: white;
}

.ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.ql-editor {
  min-height: 18em;
}

@media only screen and (max-width: 700px) {
  .request-body .content-header .title .name {
    text-align: center;
  }
  .request-body .content-header .title {
    justify-content: center;
  }
  .title-icon {
    padding-left: 0px;
  }
  .group-info-request .content .upper {
    justify-content: center;
  }
  .group-info-request .footer .row .lower {
    text-align: left;
  }
  .group-info-request .footer .row .upper {
    text-align: right;
  }
  .group-info-request .header .row .upper {
    text-align: right !important;
    padding-right: 0px !important;
  }
  .group-info-request .header .row .lower {
    text-align: left !important;
    padding-left: 0px !important;
  }
}
